import React, { useRef, useState } from 'react';

import Layout, {
    bgImageDefault,
    PageHeaderC,
    PageSection,
} from '../components/layout';
import { PageText, PageTitle } from '../components/typography';
import { useForm } from 'react-hook-form';
import * as queryString from 'query-string';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import Seo from '../components/seo';

function Contact({ data, location }) {
    const params = queryString.parse(location.search);

    return (
        <Layout
            location={location}
            headerContent={
                <PageHeaderC
                    title={<Trans i18nKey="title">Kontakt</Trans>}
                    size="small"
                />
            }
            bgImage={bgImageDefault.default}
        >
            <Seo title="Kontakt" />
            <PageSection size="small" className="lb:py-12">
                <PageTitle>
                    <Trans i18nKey="heading">Kontakt aufnehmen</Trans>
                </PageTitle>
                <PageText>
                    <Trans i18nKey="description">
                        Füllen Sie das Formular aus, um sich mit einem unserer
                        Spezialisten in Verbindung zu setzen.
                    </Trans>
                </PageText>
                <Form defaultTopic={params ? params.topic : 'normal'} />
            </PageSection>
        </Layout>
    );
}

function Form({ defaultTopic = 'normal' }) {
    const { t } = useTranslation();
    const form = useForm();
    const formRef = useRef(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = form;
    const [state, setState] = useState('');
    const [hasCompletedCaptcha, setCaptchaComplete] = useState(false);
    const isDisabled = state === 'success';

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="bg-light-grey px-6 py-4 block relative"
            ref={formRef}
        >
            <FormInput
                label={t('field_name', 'Name')}
                name="name"
                isRequired
                isDisabled={isDisabled}
                form={form}
            />
            <FormInput
                label={t('field_email', 'Email')}
                name="email"
                type="email"
                isRequired
                isDisabled={isDisabled}
                form={form}
            />
            <FormInput
                label={t('field_phone', 'Telefon Nr.')}
                name="tel"
                type="tel"
                isDisabled={isDisabled}
                form={form}
            />

            <FormField
                name={'topic'}
                label={t('field_topic', 'Thema')}
                isRequired={true}
                errors={errors}
            >
                <select
                    {...register('topic')}
                    defaultValue={defaultTopic}
                    className="block w-full bg-clean-white border-primary border-2 pl-3 pr-8 py-2 focus:outline-double"
                    disabled={isDisabled}
                >
                    <option value="normal">
                        {t('topic_normal', 'Kontakt aufnehmen')}
                    </option>
                    <option value="demo">
                        {t('topic_demo', 'Demo anfragen')}
                    </option>
                </select>
            </FormField>

            <FormField
                name={'message'}
                label={t('field_message', 'Nachricht')}
                isRequired
                errors={errors}
            >
                <textarea
                    {...register('message', { required: true })}
                    rows={7}
                    disabled={isDisabled}
                    className="block w-full bg-clean-white border-primary border-2 px-3 py-2 focus:outline-double"
                />
            </FormField>

            {state === 'success' && (
                <>
                    <div className="absolute top-0 left-0 right-0 bottom-0 bg-blue-40/80"></div>
                    <p className="bg-green-200 text-sm px-4 py-8 mt-4 text-green-700 relative">
                        <Trans i18nKey="success1">
                            Vielen Dank für Ihre Anfrage!
                        </Trans>
                        <br />
                        <Trans i18nKey="success2">
                            Wir melden uns so bald wie möglich bei Ihnen.
                        </Trans>
                    </p>
                </>
            )}

            {state === 'errorValidation' && (
                <p className="bg-red-200 text-sm p-4 mt-4 text-red-700">
                    <Trans i18nKey="errorInput">
                        Bitte überprüfen Sie Ihre Eingaben.
                    </Trans>
                </p>
            )}
            {state === 'errorGeneral' && (
                <p className="bg-red-300 text-sm p-4 mt-4 text-red-600">
                    <Trans i18nKey="error1">
                        Es gab einen Fehler beim Absenden.
                    </Trans>
                    <br />
                    <Trans i18nKey="error2">
                        Bitte versuchen Sie es erneut.
                    </Trans>
                </p>
            )}

            <Recaptcha
                onVerify={() => {
                    setCaptchaComplete(true);
                }}
            />

            <input
                type="submit"
                className="block w-full py-3 px-4 mt-10 mb-6 text-xs text-center font-bold uppercase tracking-wider bg-primary text-clean-white cursor-pointer hover:bg-blue-80 disabled:bg-blue-60 disabled:cursor-not-allowed"
                value={t('submit', 'Senden')}
                disabled={isDisabled || !hasCompletedCaptcha}
            />
        </form>
    );

    function onSubmit() {
        if (!hasCompletedCaptcha) {
            return;
        }

        const url = `${process.env.FORM_POST_URL}`;
        const data = new FormData(formRef.current);
        let jsonData = Object.fromEntries(data);
        jsonData['captcha-response'] = jsonData['h-captcha-response'] || '';
        delete jsonData['h-captcha-response'];
        delete jsonData['g-recaptcha-response'];
        setState('');

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(jsonData),
        })
            .then(response => {
                if (response.ok) {
                    form.reset();
                    setState('success');
                } else {
                    setState('errorValidation');
                }
            })
            .catch(error => {
                setState('errorGeneral');
            });
    }
}

function FormField({ name, label, errors, isRequired, children }) {
    return (
        <div className="block mb-4 w-full">
            <label className="block font-bold" htmlFor={name}>
                {label}
                {!isRequired && (
                    <span className="font-normal ml-1">(optional)</span>
                )}
            </label>
            {children}
            {errors[name]?.type === 'required' && (
                <span className="text-xs text-red-500 block mt-1">
                    Bitte dieses Feld ausfüllen
                </span>
            )}
        </div>
    );
}

function FormInput({
    name,
    label,
    type = 'text',
    isRequired = false,
    isDisabled = false,
    form,
}) {
    const {
        register,
        formState: { errors },
    } = form;
    let options = {
        required: isRequired,
    };

    return (
        <FormField
            name={name}
            label={label}
            isRequired={isRequired}
            errors={errors}
        >
            <input
                {...register(name, options)}
                type={type}
                disabled={isDisabled}
                className="block w-full bg-clean-white border-primary border-2 px-3 py-2 focus:outline-double"
            />
        </FormField>
    );
}

function Recaptcha({ onVerify }) {
    return (
        <HCaptcha
            sitekey={process.env.CAPTCHA_KEY}
            onVerify={(token, ekey) => {
                if (token) {
                    onVerify();
                }
            }}
        />
    );
}

export default Contact;

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["contact"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
